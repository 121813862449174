<template>
    <footer id="footer" class="footer border-x">
        <div class="footer__top">
            <b-container>
                <b-row>
                    <b-col lg="4">
                        <div class="wrap-info">
                            <div class="footer__logo">
                                <n-link to="/">
                                    <img src="/assets/img/logo.png" alt="logo"/>
                                </n-link>
                            </div>
                            <div class="footer__about">
                                <p>
                                    Đến với OXBET là bạn đang đến với thế giới của sự chuyên nghiệp, đẳng cấp. Với đầy đủ các hình thức giải trí từ cá cược thể thao...
                                    <n-link to="/gioi-thieu">Xem thêm</n-link>
                                </p>
                            </div>
                        </div>
                        <div class="footer__inner flex-box">
                            <div class="item-inner">
                                <img src="/assets/img/footer/logo-18plus.png" alt="18+"/>
                            </div>
                            <div class="item-inner">
                                <img src="/assets/img/footer/logo-partner.png" alt="partner"/>
                            </div>
                            <div class="item-inner">
                                <img src="/assets/img/footer/logo-mga.png" alt="mga"/>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="2">
                        <div class="footer__information">
                            <div class="footer__information-title flex-box">
                                <img src="/assets/img/footer/icon-title.svg" alt="icon"/>
                                GAMES HOT
                            </div>
                            <ul class="footer__information-menu">
                                <li v-for="(item, index) in gameHot" :key="index">
                                    <n-link :to="item.link">{{item.title}}</n-link>
                                </li>
                            </ul>
                        </div>
                    </b-col>
                    <b-col lg="3">
                        <div class="footer__information">
                            <div class="footer__information-title flex-box">
                                <img src="/assets/img/footer/icon-title.svg" alt="icon"/>THÔNG TIN & HỖ TRỢ
                            </div>
                            <ul class="footer__information-menu">
                                <li v-for="(item, index) in infoSupport" :key="index">
                                    <n-link :to="item.link">{{item.title}}</n-link>
                                </li>
                            </ul>
                        </div>
                    </b-col>
                    <b-col lg="3">
                        <div class="footer__information">
                            <div class="footer__information-title flex-box">
                                <s-img src="/assets/img/footer/icon-title.svg" alt="icon"/>HỖ TRỢ 24/7</div>
                            <ul class="footer__information-menu">
                                <!-- <li class="flex-box">
                                    <div class="icon">
                                        <s-img src="/assets/img/footer/icon-mobile.svg" alt="mobile"/>
                                    </div>
                                    <div class="text">
                                        <span>SĐT: </span>
                                        <a :href="`tel: ${this.$t('contact.phone')} `">{{this.$t('contact.phone') | fomatPhone}}</a>
                                    </div>
                                </li> -->
                                <!-- <li class="flex-box">
                                    <div class="icon">
                                        <s-img src="/assets/img/footer/logo-viber.svg" alt="viber"/>
                                    </div>
                                    <div class="text">
                                        <span>Viber: </span>
                                        <a :href="`viber://chat/?number=+84${this.$t('contact.viber')}`">{{this.$t('contact.viber')  | fomatPhone}}</a>
                                    </div>
                                </li> -->
                                <li class="flex-box">
                                    <div class="icon">
                                        <s-img src="/assets/img/footer/icon-gmail.svg" alt="gmail"/>
                                    </div>
                                    <div class="text">
                                        <span>Email: </span>
                                        <a :href="`mailto:${this.$t('contact.email')}`">{{this.$t('contact.email')}}</a>
                                    </div>
                                </li>
<!--                                <li class="flex-box">-->
<!--                                    <div class="icon">-->
<!--                                        <s-img src="/assets/img/footer/icon-zalo.svg" alt="zalo"/>-->
<!--                                    </div>-->
<!--                                    <div class="text">-->
<!--                                        <span>Zalo: </span>-->
<!--                                        <a target="_blank" :href="`https://zalo.me/${this.$t('contact.zalo')}`">{{this.$t('contact.zalo') | fomatPhone}}</a>-->
<!--                                    </div>-->
<!--                                </li>-->
                            </ul>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div class="footer__middle">
            <b-container>
                <div class="footer__bank border-x flex-box just-center">
                    <div v-for="(item, inx) in infoBanks" :key="inx" class="item">
                        <img :src="item.img" :alt="item.alt"/>
                    </div>
                </div>
            </b-container>
        </div>
        <div class="footer__bottom border-x">
            <ul class="footer__menu flex-box just-center">
                <li class="border-y">
                    <n-link to="/gioi-thieu" title="Về Chúng Tôi">Về chúng tôi</n-link>
                </li>
                <li class="border-y">
                    <n-link to="/dieu-khoan-dieu-kien" title="Điều Khoản">Điều khoản</n-link>
                </li>
                <li class="border-y">
                    <n-link to="/chinh-sach-bao-mat" title="Bảo Mật">Bảo mật</n-link>
                </li>
                <li class="border-y">
                    <n-link to="/mien-trach-nhiem" title="Miễn Trách Nhiệm">miễn trách nhiệm</n-link>
                </li>
                <li>
                    <n-link to="/huong-dan" title="Hướng Dẫn">hƯỚNG DẪN</n-link>
                </li>
            </ul>
        </div>
        <div class="footer__bottom text-center copyright-text">
            <span>Copyright @2020 by OXBET</span>
        </div>
    </footer>
</template>
<script>
/* eslint-disable linebreak-style */
export default {
    data() {
        return {
            gameHot: [
                {
                    title: 'Thể thao',
                    link: '/the-thao',
                    loginRequired: false
                },
                {
                    title: 'Thể thao ảo',
                    link: '/the-thao-ao',
                    loginRequired: false
                },
                {
                    title: 'Nổ hũ',
                    link: '/tro-choi?tab=macau&type=no-hu',
                    loginRequired: false
                },
                {
                    title: 'Numbers game',
                    link: '/tro-choi-so',
                    loginRequired: false
                },
                {
                    title: 'lô đề',
                    link: '/tro-choi-lo-de',
                    loginRequired: false
                }
            ],
            infoSupport: [
                {
                    title: 'Khuyến mãi',
                    link: '/khuyen-mai',
                    loginRequired: false
                },
                {
                    title: 'nạp tiền',
                    link: '/huong-dan-nap-tien',
                    loginRequired: false
                },
                {
                    title: 'rút tiền',
                    link: '/huong-dan-rut-tien',
                    loginRequired: false
                },
                {
                    title: 'câu hỏi thường gặp',
                    link: '/nhung-cau-hoi-thuong-gap',
                    loginRequired: false
                }
            ],
            infoBanks: [
                {
                    img: '/assets/img/footer/logo-bank/dongabank.png',
                    alt: 'dong a bank'
                },
                {
                    img: '/assets/img/footer/logo-bank/sacombank.png',
                    alt: 'sacombank'
                },
                {
                    img: '/assets/img/footer/logo-bank/acb.png',
                    alt: 'acb'
                },
                {
                    img: '/assets/img/footer/logo-bank/techcombank.png',
                    alt: 'techcombank'
                },
                {
                    img: '/assets/img/footer/logo-bank/bidv.png',
                    alt: 'bidv'
                },
                {
                    img: '/assets/img/footer/logo-bank/vietcombank.png',
                    alt: 'vietcombank'
                },
                {
                    img: '/assets/img/footer/logo-bank/vietinbank.png',
                    alt: 'vietinbank'
                },
                {
                    img: '/assets/img/footer/logo-bank/eximbank.png',
                    alt: 'eximbank'
                },
                {
                    img: '/assets/img/footer/logo-bank/mbbank.png',
                    alt: 'mb bank'
                },
                {
                    img: '/assets/img/footer/logo-bank/tpbank.png',
                    alt: 'tpbank'
                },
                {
                    img: '/assets/img/footer/logo-bank/vpbank.png',
                    alt: 'vpbank'
                },
                {
                    img: '/assets/img/footer/logo-bank/shb.png',
                    alt: 'shb'
                },
                {
                    img: '/assets/img/footer/logo-bank/vietcapital.svg',
                    alt: 'vietcapital'
                }
            ]
        };
    },
    computed: {
        footer() {
            return this.$t('footer');
        },
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
        goTo(item) {
            if (item.loginRequired && this.user === null) {
                this.$router.push('/login');
            } else if (item.link === '/the-thao-so') {
                this.$openEsports();
            } else {
                this.$fixScrollTo('html');
                this.$router.push(item.link);
            }
        }
    },
    mounted() {
    }
};
</script>
